import { config, library } from '@fortawesome/fontawesome-svg-core';
import { faCartShopping, faWarehouse, faPlateUtensils, faPeopleGroup, faContainerStorage, faPalette, faChairOffice, faChampagneGlasses, faUtensils, faMoon, faStore, faCameraMovie, faBurgerGlass, faBriefcase , faRestroom,  faFlowerDaffodil, faWheelchair, faAirConditioner, faCameraCctv, faSunBright, faWineBottle, faClothesHanger, faHeat, faCars, faShelvesEmpty, faCouch, faPlug, faCircleParking, faDogLeashed, faComputerSpeaker, faPersonArrowDownToLine } from '@fortawesome/pro-solid-svg-icons'
import { faShop, faFerrisWheel, faHouseTurret, faBuilding, faHouseTree, faHouseBuilding, faPaintbrushPencil, faTablePicnic, faHotel, faLandmark, faTruckContainer, faRectangleVerticalHistory, faShip, faShirt, faCamera, faUserTieHair, faShelves, faStoreLock, faRocketLaunch, faStreetView, faLightCeiling, faWindowFrame, faCashRegister, faWhiskeyGlassIce, faDungeon, faMountainSun, faSmoking, faVolume, faIndustry, faHatChef, faWifi, faSink, faDroplet, faBellConcierge, faCubes, faBoothCurtain, faElevator } from '@fortawesome/sharp-solid-svg-icons';
import { faCouch as sharpCouch } from '@fortawesome/sharp-solid-svg-icons';
import { faStore as sharpStore } from '@fortawesome/sharp-solid-svg-icons';
import { faChampagneGlasses as sharpCampagneGlass } from '@fortawesome/sharp-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons'

import {
  faStar,
  faThumbsUp,
  faBolt,
  faChevronLeft,
  faChevronRight,
  faMapMarkerAlt,
  faGem,
} from '@fortawesome/free-solid-svg-icons';

config.autoAddCss = false; /* eslint-disable import/first */

export default function registerIcons() {
  library.add(
    faStar,
    faThumbsUp,
    faBuilding,
    faBolt,
    faChevronLeft,
    faChevronRight,
    faMapMarkerAlt,
    faGem,
  );
}


export const spaceTypeIconMap = {
  "fa-sharp fa-solid fa-shop": faShop,
  "fa-sharp fa-solid fa-ferris-wheel": faFerrisWheel,
  "fa-sharp fa-solid fa-house-turret": faHouseTurret,
  "fa-sharp fa-solid fa-champagne-glasses": sharpCampagneGlass,
  "fa-sharp fa-solid fa-building": faBuilding,
  "fa-sharp fa-solid fa-house-tree": faHouseTree,
  "fa-sharp fa-solid fa-house-building": faHouseBuilding,
  "fa-sharp fa-solid fa-paintbrush-pencil": faPaintbrushPencil,
  "fa-sharp fa-solid fa-table-picnic": faTablePicnic,
  "fa-sharp fa-solid fa-hotel": faHotel,
  "fa-sharp fa-solid fa-landmark": faLandmark,
  "fa-sharp fa-solid fa-truck-container": faTruckContainer,
  "fa-sharp fa-solid fa-rectangle-vertical-history": faRectangleVerticalHistory,
  "fa-sharp fa-solid fa-ship": faShip,
  "fa-solid fa-cart-shopping": faCartShopping,
  "fa-solid fa-warehouse": faWarehouse,
  "fa-solid fa-camera-movie": faCameraMovie,
  "fa-solid fa-plate-utensils": faPlateUtensils,
  "fa-solid fa-people-group": faPeopleGroup,
  "fa-solid fa-container-storage": faContainerStorage,
  "fa-solid fa-palette": faPalette,
  "fa-solid fa-chair-office": faChairOffice,
}

export const projectTypeIconMap = {
  "fa-solid fa-plate-utensils": faPlateUtensils,
  "fa-solid fa-champagne-glasses": faChampagneGlasses,
  "fa-solid fa-utensils": faUtensils,
  "fa-solid fa-moon": faMoon,
  "fa-solid fa-chair-office": faChairOffice,
  "fa-solid fa-store": faStore,
  "fa-solid fa-camera-movie": faCameraMovie,
  "fa-solid fa-cart-shopping": faCartShopping,
  "fa-solid fa-burger-glass": faBurgerGlass,
  "fa-solid fa-palette": faPalette,
  "fa-solid fa-briefcase": faBriefcase,
  "fa-solid fa-people-group": faPeopleGroup,
  "fa-solid fa-briefcase": faBriefcase ,
  "fa-sharp fa-solid fa-shirt": faShirt,
  "fa-sharp fa-solid fa-camera": faCamera,
  "fa-sharp fa-solid fa-shop": faShop,
  "fa-sharp fa-solid fa-user-tie-hair": faUserTieHair,
  "fa-solid fa-sharp fa-store": sharpStore,
  "fa-sharp fa-solid fa-shelves": faShelves,
  "fa-sharp fa-solid fa-store-lock": faStoreLock,
  "fa-sharp fa-solid fa-shelves": faShelves,
  "fa-solid fa-sharp fa-rocket-launch": faRocketLaunch ,
  "fa-sharp fa-solid fa-building": faBuilding,
}



export const listingFeatureIconMap = {
"fa-sharp fa-solid fa-street-view": faStreetView,
"fa-sharp fa-solid fa-light-ceiling": faLightCeiling,
"fa-sharp fa-solid fa-rectangle-vertical-history": faRectangleVerticalHistory,
"fa-sharp fa-solid fa-window-frame": faWindowFrame,
"fa-sharp fa-solid fa-cash-register": faCashRegister,
"fa-sharp fa-solid fa-whiskey-glass-ice": faWhiskeyGlassIce,
"fa-sharp fa-solid fa-dungeon": faDungeon,
"fa-sharp fa-solid fa-couch": sharpCouch,
"fa-sharp fa-solid fa-mountain-sun": faMountainSun,
"fa-sharp fa-solid fa-smoking": faSmoking,
"fa-sharp fa-solid fa-volume": faVolume,
"fa-sharp fa-solid fa-industry": faIndustry,
"fa-sharp fa-solid fa-hat-chef": faHatChef,
"fa-sharp fa-solid fa-wifi": faWifi,
"fa-sharp fa-solid fa-sink": faSink,
"fa-sharp fa-solid fa-building": faBuilding,
"fa-sharp fa-solid fa-droplet": faDroplet,
"fa-sharp fa-solid fa-table-picnic": faTablePicnic,
"fa-sharp fa-solid fa-bell-concierge": faBellConcierge,
"fa-sharp fa-solid fa-cubes": faCubes,
"fa-sharp fa-solid fa-booth-curtain": faBoothCurtain,
"fa-sharp fa-solid fa-elevator": faElevator,

"fa-sharp fa-regular fa-square": faSquare,

"fa-solid fa-restroom": faRestroom,
"fa-solid fa-flower-daffodil": faFlowerDaffodil,
"fa-solid fa-wheelchair": faWheelchair,
"fa-solid fa-air-conditioner": faAirConditioner,
"fa-solid fa-camera-cctv": faCameraCctv,
"fa-solid fa-sun-bright": faSunBright,
"fa-solid fa-wine-bottle": faWineBottle,
"fa-solid fa-chair-office": faChairOffice,
"fa-solid fa-clothes-hanger": faClothesHanger,
"fa-solid fa-heat": faHeat,
"fa-solid fa-cars": faCars,
"fa-solid fa-shelves-empty": faShelvesEmpty,
"fa-solid fa-couch": faCouch,
"fa-solid fa-plug": faPlug,
"fa-solid fa-circle-parking": faCircleParking,
"fa-solid fa-dog-leashed": faDogLeashed,
"fa-solid fa-computer-speaker": faComputerSpeaker,
"fa-solid fa-person-arrow-down-to-line": faPersonArrowDownToLine,
}
